import { format } from "@models/date-and-time";
import styles from "./finishedItem.module.scss";
import { Text } from "@components/Text/Text";

type IFinishedItem = {
  title: string;
  timeStamp: Date;
};
export const FinishedItem = ({ title, timeStamp }: IFinishedItem) => {
  return (
    <Text element="div" size="small" className={styles.finishedItem}>
      {title}, klart {`${format(timeStamp, "HH:mm")}`}
    </Text>
  );
};

import { logisticsApi } from "./apiClient";
import { useQuery } from "@tanstack/react-query";
import { applyVisitTimes, fetchMyVisits, parseVisits } from "./visits";
import {
  ITimeOfDay,
  activityOccurrencesAndGroupsWithPatientIdsSchema,
  isGroup,
  timeOfDaySchema,
} from "@models/activities";
import { addDays, isSameDay, startOfDay, startOfToday } from "date-fns";
import { patientStatusSchema } from "@models/patients";
import { demoVisits, demoOccurrences } from "../mocks/demo";
import { usePatient } from "./patient";
import { generateQueryString } from "@/api/Helpers";

export const scheduleKeys = {
  all: ["schedule"] as const,
};

const fetchMyActivityOccurrencesAndGroups = async () => {
  const activityOccurrencesAndGroupsResponse = await logisticsApi.get(
    `/occurrences${generateQueryString({
      status: ["active", "closed"],
    })}`,
  );
  return activityOccurrencesAndGroupsWithPatientIdsSchema.parse(
    activityOccurrencesAndGroupsResponse.data,
  );
};

export const getStartTime = (
  item:
    | { kind: "activityOccurrence"; start: Date }
    | { kind: "visit"; occurrences: { start: Date }[] },
) => {
  if (item.kind === "activityOccurrence") {
    return item.start;
  }
  const lastStartingOccurrenceInVisit = item.occurrences.reduce(
    (lastStartTimeOccurrenceSoFar, nextOccurrence) =>
      lastStartTimeOccurrenceSoFar.start > nextOccurrence.start
        ? lastStartTimeOccurrenceSoFar
        : nextOccurrence,
  );
  return lastStartingOccurrenceInVisit.start;
};

export const getTimeOfDay = (
  item:
    | { kind: "activityOccurrence"; timeOfDay: ITimeOfDay }
    | { kind: "visit"; occurrences: { timeOfDay: ITimeOfDay }[] },
) => {
  if (item.kind === "activityOccurrence") {
    return item.timeOfDay;
  }
  return item.occurrences.every((occurrence) => occurrence.timeOfDay === "Any")
    ? "Any"
    : "Specific";
};

const categoryShouldBeDisplayed = (activityOccurrence: { category: string }) =>
  activityOccurrence.category === "HomeVisit" ||
  activityOccurrence.category === "VideoCall" ||
  activityOccurrence.category === "PatientTask" ||
  activityOccurrence.category === "PatientMeasurementTask";

const displayOnlyThreeDays = (activityOccurrence: { start: Date }) =>
  startOfDay(activityOccurrence.start) < addDays(startOfToday(), 3);

const fetchSampleOccurrences = (pointInTime: Date) =>
  Promise.resolve(
    activityOccurrencesAndGroupsWithPatientIdsSchema.parse(
      demoOccurrences(pointInTime),
    ),
  );

const fetchSampleVisits = (pointInTime: Date) =>
  Promise.resolve(applyVisitTimes(parseVisits(demoVisits(pointInTime))));

const fetchMySchedule = async ({
  showDemoSchedule,
}: {
  showDemoSchedule: boolean;
}) => {
  // MED-1792 Always request data, even if demo schedule is shown, to trigger expected logout behavior.
  const [realActivityOccurrencesAndGroups, realVisits] = await Promise.all([
    fetchMyActivityOccurrencesAndGroups(),
    fetchMyVisits(),
  ]);

  const now = new Date();
  const [demoActivityOccurrences, demoVisits] = await Promise.all([
    fetchSampleOccurrences(now),
    fetchSampleVisits(now),
  ]);

  const [activityOccurrencesAndGroups, visits] = showDemoSchedule
    ? [demoActivityOccurrences, demoVisits]
    : [realActivityOccurrencesAndGroups, realVisits];

  const notInVisit = (activityOccurrence: { id: string }) =>
    !visits.some((visit) =>
      visit.occurrences
        .map((occurrence) => occurrence.id)
        .includes(activityOccurrence.id),
    );

  // This ensures that we don't have to deal with displaying groups in Care.
  const activityOccurrences = activityOccurrencesAndGroups.flatMap(
    (activityOccurrenceOrGroup) =>
      isGroup(activityOccurrenceOrGroup)
        ? activityOccurrenceOrGroup.occurrences
        : [activityOccurrenceOrGroup],
  );

  const activityOccurrenceToDisplayInSchedule = activityOccurrences
    .filter(categoryShouldBeDisplayed)
    .filter(notInVisit)
    .filter(displayOnlyThreeDays);

  const distinguishableActivityOccurrences =
    activityOccurrenceToDisplayInSchedule.map((activityOccurrence) => ({
      ...activityOccurrence,
      kind: "activityOccurrence" as const,
    }));

  const distinguishableVisits = visits.map((visit) => ({
    ...visit,
    kind: "visit" as const,
    timeOfDay: visit.occurrences.every(
      ({ timeOfDay }) => timeOfDay === timeOfDaySchema.Values.Any,
    )
      ? timeOfDaySchema.Values.Any
      : timeOfDaySchema.Values.Specific,
  }));

  const schedule = [
    ...distinguishableActivityOccurrences,
    ...distinguishableVisits,
  ];

  schedule.sort((a, b) => {
    // Finished visits/activityOccurrences should be displayed first, if they are on the same day
    if (
      a.status === "finished" &&
      b.status !== "finished" &&
      isSameDay(getStartTime(a), getStartTime(b))
    ) {
      return -1;
    }
    if (
      b.status === "finished" &&
      a.status !== "finished" &&
      isSameDay(getStartTime(a), getStartTime(b))
    ) {
      return 1;
    }
    // Sort any time of day activities last
    if (
      getTimeOfDay(a) === timeOfDaySchema.Values.Any &&
      getTimeOfDay(b) !== timeOfDaySchema.Values.Any &&
      isSameDay(getStartTime(a), getStartTime(b))
    ) {
      return 1;
    }
    if (
      getTimeOfDay(b) === timeOfDaySchema.Values.Any &&
      getTimeOfDay(a) !== timeOfDaySchema.Values.Any &&
      isSameDay(getStartTime(a), getStartTime(b))
    ) {
      return -1;
    }

    if (getStartTime(a) < getStartTime(b)) {
      return -1;
    }
    if (getStartTime(a) > getStartTime(b)) {
      return 1;
    }
    return 0;
  });

  const groupedSchedule = schedule.reduce(
    (groups, item) => {
      const lastGroup = groups[groups.length - 1];
      if (!lastGroup) {
        return groups;
      }
      if (lastGroup.length === 0) {
        lastGroup.push(item);
        return groups;
      }
      const lastItem = lastGroup[lastGroup.length - 1];
      if (!lastItem) {
        return groups;
      }
      if (getStartTime(lastItem).getDate() === getStartTime(item).getDate()) {
        lastGroup.push(item);
        return groups;
      }
      groups.push([item]);
      return groups;
    },
    [[]] as (typeof schedule)[],
  );

  return groupedSchedule;
};

const ONE_MINUTE = 60000;
export const useMySchedule = () => {
  const { data: patient } = usePatient();
  const showDemoSchedule =
    patient?.status === patientStatusSchema.Values.prospect;

  return useQuery({
    enabled: patient !== undefined,
    queryKey: [...scheduleKeys.all, showDemoSchedule],
    queryFn: () => fetchMySchedule({ showDemoSchedule }),
    // Activity occurrences have time based status logic, so we need to refetch them often where status matters/is shown.
    refetchInterval: ONE_MINUTE,
  });
};

import { logisticsApi } from "@/api/apiClient";
import { useQuery } from "@tanstack/react-query";
import { acceptedPatientNoteSchema } from "@models/patientNotes";

const fetchAcceptedPatientNote = async () => {
  try {
    const patientNoteResponse = await logisticsApi.get(
      "conversion/patient-note/latest",
    );

    return acceptedPatientNoteSchema.parse(patientNoteResponse.data);
  } catch (_error) {
    // In case of error, we assume NotFound, caused by there not being any patient note.
    // Thus, return null.
    // TODO: Handle other errors.
    return null;
  }
};

const patientNoteKeys = {
  all: ["conversion"] as const,
  lists: () => [...patientNoteKeys.all, "list"] as const,
  list: (filters: Record<string, unknown>) =>
    [...patientNoteKeys.lists(), filters] as const,
  detail: ["details"] as const,
};

export const useAcceptedPatientNote = () => {
  return useQuery({
    queryKey: patientNoteKeys.detail,
    queryFn: fetchAcceptedPatientNote,
    staleTime: 5000,
  });
};

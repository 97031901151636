import { format } from "@models/date-and-time";
import {
  addDays,
  addHours,
  formatISO,
  startOfDay,
  startOfHour,
} from "date-fns";

export const demoMeasurementId = "demo-measurement";
export const demoOccurrences = (pointInTime: Date) => {
  const startOfThisHour = formatISO(startOfHour(pointInTime));
  const inTwoHours = formatISO(addHours(startOfHour(pointInTime), 2));
  const tomorrow = format(addDays(startOfDay(pointInTime), 1), "yyyy-MM-dd");
  const dayAfterTomorrow = format(
    addDays(startOfDay(pointInTime), 2),
    "yyyy-MM-dd",
  );
  return [
    {
      id: demoMeasurementId,
      activityId: "42bbee57-647b-4a56-951d-b5c11f83f518",
      title: "Testmätning",
      description: "",
      start: startOfThisHour,
      end: inTwoHours,
      timeOfDay: "Specific",
      recurring: true,
      duration: 15,
      status: "ready",
      hidden: false,
      assignees: [
        {
          type: "Patient",
        },
      ],
      patientId: "a512479f-c18e-43cc-860f-812759660d9d",
      measurements: ["pulse", "bloodPressure", "saturation", "temperature"],
      category: "PatientMeasurementTask",
    },
    {
      id: "9c3b8a45-b854-40ac-8c95-f83fae45b431",
      activityId: "9ba5fd01-6840-4a70-a215-cd573934e7a4",
      title: "Inskrivning",
      description: "",
      start: `${tomorrow}T12:00:00.0000000Z`,
      end: `${tomorrow}T14:00:00.0000000Z`,
      timeOfDay: "Specific",
      recurring: false,
      duration: 15,
      status: "ready",
      hidden: false,
      assignees: [
        {
          id: 2792254,
          employee: {
            id: 5955,
            matrixUserId: "9d2ba942-a145-4b22-85f9-29a8ea115631",
            firstName: "Sofia",
            lastName: "Brandin",
            competence: "NursePractitioner",
            imageURL:
              "https://imgmedia.blob.core.windows.net/blob/IMG_6697.JPG",
          },
          startDateTime: `${tomorrow}T14:00:00.0000000Z`,
          endDateTime: `${tomorrow}T21:00:00.0000000Z`,
          competence: "NursePractitioner",
          type: "Ambulating",
        },
        {
          id: 2792257,
          employee: {
            id: 17157,
            matrixUserId: "9d2ba942-a145-4b22-85f9-29a8ea115631",
            firstName: "Hanna ",
            lastName: "Sundberg",
            competence: "Support",
            imageURL:
              "https://imgmedia.blob.core.windows.net/blob/654363425243543245765745.JPG",
          },
          startDateTime: `${tomorrow}T06:00:00.0000000Z`,
          endDateTime: `${tomorrow}T12:00:00.0000000Z`,
          competence: "Support",
          type: "Ambulating",
        },
      ],
      patientId: "a512479f-c18e-43cc-860f-812759660d9d",
      requiredCompetences: ["Support"],
      category: "HomeVisit",
      doubleStaffing: false,
    },
    {
      id: "d6064e91-ca5c-4841-9e04-295baf001e7d",
      activityId: "3d82d2bf-e4dc-43df-82ee-9512b6698ad5",
      title: "Genomgång av tekniken",
      description: "",
      start: `${tomorrow}T12:00:00.0000000Z`,
      end: `${tomorrow}T14:00:00.0000000Z`,
      timeOfDay: "Specific",
      recurring: false,
      duration: 15,
      status: "ready",
      hidden: false,
      assignees: [
        {
          id: 2792254,
          employee: {
            id: 5955,
            matrixUserId: "9d2ba942-a145-4b22-85f9-29a8ea115631",
            firstName: "Sofia",
            lastName: "Brandin",
            competence: "NursePractitioner",
            imageURL:
              "https://imgmedia.blob.core.windows.net/blob/IMG_6697.JPG",
          },
          startDateTime: `${tomorrow}T14:00:00.0000000Z`,
          endDateTime: `${tomorrow}T21:00:00.0000000Z`,
          competence: "NursePractitioner",
          type: "Ambulating",
        },
        {
          id: 2792257,
          employee: {
            id: 17157,
            matrixUserId: "9d2ba942-a145-4b22-85f9-29a8ea115631",
            firstName: "Hanna ",
            lastName: "Sundberg",
            competence: "Support",
            imageURL:
              "https://imgmedia.blob.core.windows.net/blob/654363425243543245765745.JPG",
          },
          startDateTime: `${tomorrow}T06:00:00.0000000Z`,
          endDateTime: `${tomorrow}T12:00:00.0000000Z`,
          competence: "Support",
          type: "Ambulating",
        },
      ],
      patientId: "a512479f-c18e-43cc-860f-812759660d9d",
      requiredCompetences: ["Support"],
      category: "HomeVisit",
      doubleStaffing: false,
    },
    {
      id: "07438d74-0585-4ec7-a52b-12b6794de530",
      activityId: "378992ee-f273-42b1-99c3-72fe064480f6",
      title: "Lämna läkemedelslista",
      description: "Se över läkemedel samtidigt.",
      start: `${tomorrow}T12:00:00.0000000Z`,
      end: `${tomorrow}T14:00:00.0000000Z`,
      timeOfDay: "Specific",
      recurring: false,
      duration: 15,
      status: "ready",
      hidden: false,
      assignees: [
        {
          id: 2792254,
          employee: {
            id: 5955,
            matrixUserId: "9d2ba942-a145-4b22-85f9-29a8ea115631",
            firstName: "Sofia",
            lastName: "Brandin",
            competence: "NursePractitioner",
            imageURL:
              "https://imgmedia.blob.core.windows.net/blob/IMG_6697.JPG",
          },
          startDateTime: `${tomorrow}T14:00:00.0000000Z`,
          endDateTime: `${tomorrow}T21:00:00.0000000Z`,
          competence: "NursePractitioner",
          type: "Ambulating",
        },
        {
          id: 2792257,
          employee: {
            id: 17157,
            matrixUserId: "9d2ba942-a145-4b22-85f9-29a8ea115631",
            firstName: "Hanna ",
            lastName: "Sundberg",
            competence: "Support",
            imageURL:
              "https://imgmedia.blob.core.windows.net/blob/654363425243543245765745.JPG",
          },
          startDateTime: `${tomorrow}T06:00:00.0000000Z`,
          endDateTime: `${tomorrow}T12:00:00.0000000Z`,
          competence: "Support",
          type: "Ambulating",
        },
      ],
      patientId: "a512479f-c18e-43cc-860f-812759660d9d",
      requiredCompetences: ["NursePractitioner"],
      category: "HomeVisit",
      doubleStaffing: false,
    },
    {
      id: "1000d3b8-8e29-4298-bdfe-d2c5bbc1df52",
      activityId: "6584ec62-4398-4a5e-bd32-b9879fca668d",
      title: "Lämna materiallåda",
      description: "",
      start: `${tomorrow}T12:00:00.0000000Z`,
      end: `${tomorrow}T14:00:00.0000000Z`,
      timeOfDay: "Specific",
      recurring: false,
      duration: 15,
      status: "ready",
      hidden: false,
      assignees: [
        {
          id: 2792254,
          employee: {
            id: 5955,
            matrixUserId: "9d2ba942-a145-4b22-85f9-29a8ea115631",
            firstName: "Sofia",
            lastName: "Brandin",
            competence: "NursePractitioner",
            imageURL:
              "https://imgmedia.blob.core.windows.net/blob/IMG_6697.JPG",
          },
          startDateTime: `${tomorrow}T14:00:00.0000000Z`,
          endDateTime: `${tomorrow}T21:00:00.0000000Z`,
          competence: "NursePractitioner",
          type: "Ambulating",
        },
        {
          id: 2792257,
          employee: {
            id: 17157,
            matrixUserId: "9d2ba942-a145-4b22-85f9-29a8ea115631",
            firstName: "Hanna ",
            lastName: "Sundberg",
            competence: "Support",
            imageURL:
              "https://imgmedia.blob.core.windows.net/blob/654363425243543245765745.JPG",
          },
          startDateTime: `${tomorrow}T06:00:00.0000000Z`,
          endDateTime: `${tomorrow}T12:00:00.0000000Z`,
          competence: "Support",
          type: "Ambulating",
        },
      ],
      patientId: "a512479f-c18e-43cc-860f-812759660d9d",
      requiredCompetences: ["Support"],
      category: "HomeVisit",
      doubleStaffing: false,
    },
    {
      id: "20231114-1900",
      activityId: "42bbee57-647b-4a56-951d-b5c11f83f519",
      title: "Egen mätning",
      description: "",
      start: `${tomorrow}T18:00:00.0000000Z`,
      end: `${tomorrow}T19:00:00.0000000Z`,
      timeOfDay: "Specific",
      recurring: true,
      duration: 15,
      status: "ready",
      hidden: false,
      assignees: [
        {
          type: "Patient",
        },
      ],
      patientId: "a512479f-c18e-43cc-860f-812759660d9d",
      measurements: ["pulse", "bloodPressure", "saturation", "temperature"],
      category: "PatientMeasurementTask",
    },
    {
      id: "20231115-0700",
      activityId: "084818af-451d-4406-924b-847c96291a3a",
      title: "Blodprov",
      description: "",
      start: `${dayAfterTomorrow}T07:00:00.0000000Z`,
      end: `${dayAfterTomorrow}T08:00:00.0000000Z`,
      timeOfDay: "Specific",
      recurring: true,
      duration: 15,
      status: "notReady",
      hidden: false,
      assignees: [],
      patientId: "a512479f-c18e-43cc-860f-812759660d9d",
      requiredCompetences: ["NurseAssistant"],
      category: "HomeVisit",
      doubleStaffing: false,
    },
    {
      id: "20231115-0700",
      activityId: "42bbee57-647b-4a56-951d-b5c11f83f519",
      title: "Egen mätning",
      description: "",
      start: `${dayAfterTomorrow}T07:00:00.0000000Z`,
      end: `${dayAfterTomorrow}T08:00:00.0000000Z`,
      timeOfDay: "Specific",
      recurring: true,
      duration: 15,
      status: "ready",
      hidden: false,
      assignees: [
        {
          type: "Patient",
        },
      ],
      patientId: "a512479f-c18e-43cc-860f-812759660d9d",
      measurements: ["pulse", "bloodPressure", "saturation", "temperature"],
      category: "PatientMeasurementTask",
    },
    {
      id: "20231115-0800",
      activityId: "c9518a5a-55c0-4767-a82e-59ef8fa35e25",
      title: "Rond",
      description: "",
      start: `${dayAfterTomorrow}T08:00:00.0000000Z`,
      end: `${dayAfterTomorrow}T10:00:00.0000000Z`,
      timeOfDay: "Specific",
      recurring: true,
      duration: 15,
      status: "notReady",
      hidden: false,
      assignees: [],
      patientId: "a512479f-c18e-43cc-860f-812759660d9d",
      requiredCompetences: ["NursePractitioner", "MedicalDoctor"],
      category: "VideoCall",
      type: "DigitalVisit",
    },
    {
      id: "20231115-1900",
      activityId: "42bbee57-647b-4a56-951d-b5c11f83f519",
      title: "Egen mätning",
      description: "",
      start: `${dayAfterTomorrow}T18:00:00.0000000Z`,
      end: `${dayAfterTomorrow}T19:00:00.0000000Z`,
      timeOfDay: "Specific",
      recurring: true,
      duration: 15,
      status: "ready",
      hidden: false,
      assignees: [
        {
          type: "Patient",
        },
      ],
      patientId: "a512479f-c18e-43cc-860f-812759660d9d",
      measurements: ["pulse", "bloodPressure", "saturation", "temperature"],
      category: "PatientMeasurementTask",
    },
  ];
};

export const demoVisits = (pointInTime: Date) => {
  const tomorrow = format(addDays(startOfDay(pointInTime), 1), "yyyy-MM-dd");
  return [
    {
      id: "7e267a9c-8165-417c-8ca3-eb0b0c65b3dc",
      patientId: "a512479f-c18e-43cc-860f-812759660d9d",
      occurrences: [
        {
          id: "9c3b8a45-b854-40ac-8c95-f83fae45b431",
          activityId: "9ba5fd01-6840-4a70-a215-cd573934e7a4",
          title: "Inskrivning",
          description: "",
          start: `${tomorrow}T12:00:00.0000000Z`,
          end: `${tomorrow}T14:00:00.0000000Z`,
          recurring: false,
          duration: 15,
          timeOfDay: "Specific",
          hidden: false,
          status: "ready",
          patientId: "a512479f-c18e-43cc-860f-812759660d9d",
          requiredCompetences: ["Support"],
          category: "HomeVisit",
          doubleStaffing: false,
        },
        {
          id: "d6064e91-ca5c-4841-9e04-295baf001e7d",
          activityId: "3d82d2bf-e4dc-43df-82ee-9512b6698ad5",
          title: "Genomgång av tekniken",
          description: "",
          start: `${tomorrow}T12:00:00.0000000Z`,
          end: `${tomorrow}T14:00:00.0000000Z`,
          recurring: false,
          duration: 15,
          timeOfDay: "Specific",
          hidden: false,
          status: "ready",
          patientId: "a512479f-c18e-43cc-860f-812759660d9d",
          requiredCompetences: ["Support"],
          category: "HomeVisit",
          doubleStaffing: false,
        },
        {
          id: "07438d74-0585-4ec7-a52b-12b6794de530",
          activityId: "378992ee-f273-42b1-99c3-72fe064480f6",
          title: "Lämna läkemedelslista",
          description: "Se över läkemedel samtidigt.",
          start: `${tomorrow}T12:00:00.0000000Z`,
          end: `${tomorrow}T14:00:00.0000000Z`,
          recurring: false,
          duration: 15,
          timeOfDay: "Specific",
          hidden: false,
          status: "ready",
          patientId: "a512479f-c18e-43cc-860f-812759660d9d",
          requiredCompetences: ["NursePractitioner"],
          category: "HomeVisit",
          doubleStaffing: false,
        },
        {
          id: "1000d3b8-8e29-4298-bdfe-d2c5bbc1df52",
          activityId: "6584ec62-4398-4a5e-bd32-b9879fca668d",
          title: "Lämna materiallåda",
          description: "",
          start: `${tomorrow}T12:00:00.0000000Z`,
          end: `${tomorrow}T14:00:00.0000000Z`,
          recurring: false,
          duration: 15,
          timeOfDay: "Specific",
          hidden: false,
          status: "ready",
          patientId: "a512479f-c18e-43cc-860f-812759660d9d",
          requiredCompetences: ["Support"],
          category: "HomeVisit",
          doubleStaffing: false,
        },
      ],
      assignees: [
        {
          id: 2792254,
          employee: {
            id: 5955,
            matrixUserId: "9d2ba942-a145-4b22-85f9-29a8ea115631",
            firstName: "Sofia",
            lastName: "Brandin",
            competence: "NursePractitioner",
            imageURL:
              "https://imgmedia.blob.core.windows.net/blob/IMG_6697.JPG",
          },
          startDateTime: `${tomorrow}T14:00:00.0000000Z`,
          endDateTime: `${tomorrow}T21:00:00.0000000Z`,
          competence: "NursePractitioner",
          type: "Ambulating",
        },
        {
          id: 2792257,
          employee: {
            id: 17157,
            matrixUserId: "9d2ba942-a145-4b22-85f9-29a8ea115631",
            firstName: "Hanna ",
            lastName: "Sundberg",
            competence: "Support",
            imageURL:
              "https://imgmedia.blob.core.windows.net/blob/654363425243543245765745.JPG",
          },
          startDateTime: `${tomorrow}T06:00:00.0000000Z`,
          endDateTime: `${tomorrow}T12:00:00.0000000Z`,
          competence: "Support",
          type: "Ambulating",
        },
      ],
      startedTravellingToAt: null,
      startedAt: null,
      finishedAt: null,
      status: "planned",
    },
  ];
};

import { z } from "zod";
import { patientApi } from "./apiClient";

const jwtSchema = z.object({ baseUrl: z.string(), token: z.string() });

export const getCareJwt = async () => {
  const url = `chat/token`;
  const response = await patientApi.post(url);
  return jwtSchema.parse(response.data);
};

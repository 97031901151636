import axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import {
  LOGISTICS_API_URL,
  AUTH_API_URL,
  PATIENT_API_URL,
  NOTIFICATION_API_URL,
  userManager,
} from "../utils/envUtils";
import { User } from "oidc-client-ts";

export const logisticsApi = axios.create({
  baseURL: LOGISTICS_API_URL,
});

export const patientApi = axios.create({
  baseURL: PATIENT_API_URL,
});

export const notificationApi = axios.create({
  baseURL: NOTIFICATION_API_URL,
});

const signinRedirect = async () => {
  userManager.signinRedirect({
    state: window.location.pathname,
  });
};

export const getAuthenticatedUser = () => {
  const oidcStorage = sessionStorage.getItem(
    `oidc.user:${AUTH_API_URL}:medoma-care-reference`,
  );
  if (!oidcStorage) {
    return null;
  }

  const user = User.fromStorageString(oidcStorage);
  if (user?.expired) {
    signinRedirect();
  }

  return user;
};

const injectBearerToken = (
  config: InternalAxiosRequestConfig,
  token: string | undefined,
) => {
  if (config && config.headers) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
};

const requestHandler = (config: InternalAxiosRequestConfig) => {
  // Don't look for access token in test scenario.
  if (import.meta.env.MODE === "test") return config;
  const unexpiredUser = getAuthenticatedUser();
  return injectBearerToken(config, unexpiredUser?.access_token);
};

const responseHandler = (response: AxiosResponse) => {
  return response;
};

const handleUnauthenticatedError = (error: unknown) => {
  if (
    error &&
    typeof error === "object" &&
    "response" in error &&
    error.response &&
    typeof error.response === "object" &&
    "status" in error.response &&
    error.response.status === 401
  ) {
    userManager.signinRedirect({
      state: window.location.pathname,
    });
  } else {
    return Promise.reject(error);
  }
};

const errorHandler = (error: unknown) => {
  return handleUnauthenticatedError(error);
};

notificationApi.interceptors.request.use(requestHandler);
notificationApi.interceptors.response.use(responseHandler, errorHandler);

logisticsApi.interceptors.request.use(requestHandler);
logisticsApi.interceptors.response.use(responseHandler, errorHandler);

patientApi.interceptors.request.use(requestHandler);
patientApi.interceptors.response.use(responseHandler, errorHandler);

import styles from "./assigneeList.module.scss";
import { Text } from "@components/Text/Text";
import {
  medicalCompetenceDictionary,
  employee as employeeSchema,
  IScheduledShift,
  employeeName,
} from "@models/shifts";
import Portrait from "@components/Portrait/Portrait";

const Assignee = ({ shift }: { shift: IScheduledShift }) => {
  const { employee } = shift;
  // Filter above is the real typeguard, this is for Typescript to understand
  const parsedEmployee = employeeSchema.parse(employee);
  const displayName = employeeName(parsedEmployee, { format: "long" });
  return (
    <li key={shift.id} className={styles.employee}>
      <Portrait
        name={displayName}
        photoUrl={parsedEmployee.imageURL}
        size="large"
      />
      <div>
        <Text element="div">{displayName}</Text>
        <Text element="div" size="small">
          {medicalCompetenceDictionary[parsedEmployee.competence].sv.long}
        </Text>
      </div>
    </li>
  );
};

export const AssigneeList = ({
  assignees,
}: {
  assignees: IScheduledShift[];
}) => (
  <ul className={styles.employeeList}>
    {assignees.map((shift) => (
      <Assignee key={shift.id} shift={shift} />
    ))}
  </ul>
);

import { useQuery } from "@tanstack/react-query";
import { patientApi } from "./apiClient";
import { patientSchema } from "@models/patients";

export const patientKeys = {
  detail: ["patient"] as const,
};

const fetchPatient = async () => {
  const patientResponse = await patientApi.get("patient");
  return patientSchema.parse(patientResponse.data);
};

export const usePatient = () => {
  return useQuery({ queryKey: patientKeys.detail, queryFn: fetchPatient });
};

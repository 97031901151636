import { FilledButton } from "@components/Button/Button";
import { Iframe } from "@components/Iframe/Iframe";
import { useNavigate } from "react-router-dom";
import styles from "./CompodiumVideo.module.scss";
import { useEffect, useState } from "react";
import { Loading } from "@components/Loading/Loading";

export const CompodiumVideo = ({ meetingUrl }: { meetingUrl: string }) => {
  const navigate = useNavigate();
  const [permissionsSurfaced, setPermissionsSurfaced] = useState(false);
  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then(() =>
        // DEBUGGING: Log available devices to clarify what Compodium probably sees
        navigator.mediaDevices.enumerateDevices().then((devices) => {
          devices.forEach((device, index) =>
            console.log(`DEVICE ${index + 1}:`, device.toJSON()),
          );
        }),
      )
      .finally(() => setPermissionsSurfaced(true));
  }, []);
  return !permissionsSurfaced ? (
    <Loading message="Ger videotjänsten tillgång till mikrofon och kamera" />
  ) : (
    <>
      <Iframe
        src={meetingUrl}
        allow="camera;microphone;"
        loadingMessage="Startar video"
        title="Video"
      />
      <div className={styles.buttonWrapper}>
        <FilledButton
          onClick={() => {
            navigate("/schedule");
            if (window.ReactNativeWebView) {
              window.ReactNativeWebView.postMessage("video:finished");
            }
          }}
        >
          Gå till schemat
        </FilledButton>
      </div>
    </>
  );
};

import { logisticsApi } from "./apiClient";

export const finishActivityOccurrence = async (
  activityId: string,
  occurrenceId: string,
) => {
  await logisticsApi.post(
    `activities/${activityId}/occurrences/${occurrenceId}/finish`,
  );
};

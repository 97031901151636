import { useEventSource } from "@/utils/sse";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { patientKeys } from "@/api/patient";
import { scheduleKeys } from "@/api/schedule";

const ALL_KNOWN_LOGISTICS_EVENTS = [
  "routeCreated",
  "routeRemoved",
  "visitOrderChanged",
  "routePublished",
  "routeFinished",
  "visitCreated",
  "visitRemoved",
  "travelToVisitStarted",
  "visitStarted",
  "visitFinished",
  "travellingToVisitReverted",
  "activityOccurrenceAssigned",
  "activityOccurrenceUnassigned",
  "routeNameChanged",
  "homeVisitActivityRegistered",
  "videoCallActivityRegistered",
  "patientTaskActivityRegistered",
  "patientMeasurementTaskActivityRegistered",
  "adminTaskActivityRegistered",
  "activityVisibilitySet",
  "titleChanged",
  "descriptionChanged",
  "durationChanged",
  "activityEndDateSet",
  "activityClosed",
  "occurrenceAllocated",
  "occurrenceDeallocated",
  "occurrenceRemoved",
  "occurrenceReset",
  "occurrenceFinished",
  "occurrenceCreated",
  "occurrenceRescheduled",
  "scheduleFrequencyChanged",
  "homeVisitCompetenceChanged",
  "videoCallCompetenceChanged",
  "homeVisitDoubleStaffingChanged",
  "adminTaskPatientConnected",
  "adminTaskCompetenceChanged",
  "patientMeasurementTaskMeasurementsChanged",
];

export const useCareSSEHandlers = () => {
  const queryClient = useQueryClient();

  const [patientEventHandlers] = useState(() => [
    {
      relevantEvents: ["patientAdmitted"],
      handler: () => {
        queryClient.invalidateQueries({ queryKey: patientKeys.detail });
      },
    },
  ]);
  useEventSource("patient", patientEventHandlers);

  const [logisticsEventHandlers] = useState(() => [
    {
      relevantEvents: ALL_KNOWN_LOGISTICS_EVENTS,
      handler: () => {
        queryClient.invalidateQueries({ queryKey: scheduleKeys.all });
      },
    },
  ]);
  useEventSource("logistics", logisticsEventHandlers);
};

import { isToday, isTomorrow } from "date-fns";
import { Heading } from "@components/Heading/Heading";
import styles from "./scheduleDayHeading.module.scss";
import { format } from "@models/date-and-time";

export const ScheduleDayHeading = ({ date }: { date: Date }) => {
  const dayAndMonth = format(date, "d MMMM");

  const weekday = format(date, "eeee");

  const weekdayCapitalized = weekday.charAt(0).toUpperCase() + weekday.slice(1);

  const day = isToday(date)
    ? "Idag"
    : isTomorrow(date)
      ? "Imorgon"
      : weekdayCapitalized;

  return (
    <header>
      <Heading level="h2" size="h1" className={styles.heading}>
        {day}
        <span className={styles.dayAndMonth}>{dayAndMonth}</span>
      </Heading>
    </header>
  );
};

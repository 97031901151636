import { calculateVisitTimes, visitSchemaWithPatientId } from "@models/visits";
import { z } from "zod";
import { logisticsApi } from "./apiClient";

const visitWithoutPatientSchema = visitSchemaWithPatientId.omit({
  patientId: true,
});

export const parseVisits = (maybeVisits: unknown) => {
  const parsedVisits = z.array(visitWithoutPatientSchema).parse(maybeVisits);
  return parsedVisits;
};

export const applyVisitTimes = (
  visits: z.infer<typeof visitWithoutPatientSchema>[],
) => {
  const visitsWithStartAndEnd = visits.map((visit) => {
    return {
      ...visit,
      ...calculateVisitTimes(visit),
    };
  });
  return visitsWithStartAndEnd;
};

export const fetchMyVisits = async () => {
  const visitsResponse = await logisticsApi.get(`/visits`);
  const parsedVisits = parseVisits(visitsResponse.data);
  const visitsWithStartAndEnd = applyVisitTimes(parsedVisits);
  return visitsWithStartAndEnd;
};

// TESTS
if (import.meta.vitest) {
  const { it, expect } = import.meta.vitest;
  const time = (hours: number, minutes: number) => {
    return new Date(2021, 1, 1, hours, minutes);
  };
  it("calculateVisitTimes returns the latest start and earliest end when occurrences overlap", () => {
    const expectedStart = time(12, 30);
    const expectedEnd = time(13, 0);
    const visit = {
      occurrences: [
        {
          start: time(12, 0),
          end: time(13, 0),
          timeOfDay: "Specific" as const,
        },
        {
          start: time(12, 30),
          end: time(13, 30),
          timeOfDay: "Specific" as const,
        },
        {
          start: time(11, 45),
          end: time(13, 15),
          timeOfDay: "Specific" as const,
        },
      ],
    };

    expect(calculateVisitTimes(visit).start).toStrictEqual(expectedStart);
    expect(calculateVisitTimes(visit).end).toStrictEqual(expectedEnd);
  });

  it("calculateVisitTimes returns undefined start and undefined end when occurrences don't overlap", () => {
    const visit = {
      occurrences: [
        {
          start: time(12, 0),
          end: time(13, 0),
          timeOfDay: "Specific" as const,
        },
        {
          start: time(13, 30),
          end: time(14, 30),
          timeOfDay: "Specific" as const,
        },
      ],
    };

    expect(calculateVisitTimes(visit).start).toStrictEqual(undefined);
    expect(calculateVisitTimes(visit).end).toStrictEqual(undefined);
  });
}

import { IPlainButton, PlainButton } from "@components/Button/Button";
import ArrowLeftIcon from "@components/icons/ArrowLeftIcon";

type IGoBackButton = Omit<IPlainButton, "children">;

export const GoBackButton = (props: IGoBackButton) => {
  return (
    <PlainButton contentType="text-and-icon" {...props}>
      <ArrowLeftIcon /> Tillbaka
    </PlainButton>
  );
};

import { useMySchedule, getStartTime } from "@/api/schedule";
import { Loading } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { VisitCard } from "./visitCard";
import { ActivityCard } from "./activityCard";
import styles from "./schedule.module.scss";
import { Text } from "@components/Text/Text";
import { ScheduleDayHeading } from "@/routes/schedule/scheduleDayHeading";
import { FinishedItem } from "./finishedItem";
import { Heading } from "@components/Heading/Heading";
import CheckFilledIcon from "@components/icons/CheckFilledIcon";
import * as Sentry from "@sentry/react";
import { format } from "@models/date-and-time";

export const Schedule = () => {
  const { data: schedule, isPending, isError, error } = useMySchedule();

  if (isPending) {
    return <Loading message="Hämtar dina aktiviteter" />;
  }

  // Log errors when fetching schedule, even if we prefer to show old data.
  if (isError) {
    Sentry.captureException(error);
  }

  // Show (old) data rather than error, even if there is an error
  if (!schedule && isError) {
    return (
      <ErrorMessage message="Något gick fel när vi hämtade dina aktiviteter" />
    );
  }

  const daysWithItems = schedule.filter((scheduleItemsPerDay) =>
    scheduleItemsPerDay.some((visitOrActivityOccurrence) =>
      Boolean(visitOrActivityOccurrence),
    ),
  );

  if (daysWithItems.length === 0) {
    return (
      <Text element="span">
        Det finns inga aktiviteter inplanerade för dig. Om du har frågor kan du
        skriva till oss i chatten
      </Text>
    );
  }

  return (
    <ol className={styles.schedule}>
      {schedule.map((scheduleItemsPerDay) => {
        const oneScheduledItemFromThatDay = scheduleItemsPerDay[0];
        if (!oneScheduledItemFromThatDay) {
          return null;
        }
        const date = getStartTime(oneScheduledItemFromThatDay);
        const dateFormatted = format(date, "eeee, d MMMM");
        const finishedItems = scheduleItemsPerDay.filter(
          (visitOrActivityOccurrence) =>
            visitOrActivityOccurrence.status === "finished",
        );
        const unfinishedItems = scheduleItemsPerDay.filter(
          (visitOrActivityOccurrence) =>
            visitOrActivityOccurrence.status !== "finished",
        );

        return (
          <li key={dateFormatted} className={styles.daySchedule}>
            <ScheduleDayHeading date={date} />
            {finishedItems.length > 0 ? (
              <ol className={styles.finishedItems}>
                <div className={styles.finishedItemsHeader}>
                  <span className={styles.checkMark}>
                    <CheckFilledIcon />
                  </span>
                  <Heading level="h2" size="h3" weight="medium">
                    Utfört under dagen
                  </Heading>
                </div>
                {finishedItems.map((finishedItem) => {
                  if (finishedItem.finishedAt) {
                    // This is always true, but TS doesn't know that
                    if (finishedItem.kind === "visit") {
                      return (
                        <li key={finishedItem.id}>
                          <FinishedItem
                            title="Hembesök"
                            timeStamp={finishedItem.finishedAt}
                          />
                        </li>
                      );
                    } else {
                      return (
                        <li
                          key={`${finishedItem.activityId}${finishedItem.id}`}
                        >
                          <FinishedItem
                            title={finishedItem.title}
                            timeStamp={finishedItem.finishedAt}
                          />
                        </li>
                      );
                    }
                  } else {
                    return null;
                  }
                })}
              </ol>
            ) : null}
            <ol className={styles.unfinishedItems}>
              {unfinishedItems.map((visitOrActivityOccurrence) => {
                if (visitOrActivityOccurrence.kind === "visit") {
                  const visit = visitOrActivityOccurrence;
                  return (
                    <li key={visit.id}>
                      <VisitCard visit={visit} />
                    </li>
                  );
                } else {
                  const activityOccurrence = visitOrActivityOccurrence;
                  return (
                    <li
                      key={`${activityOccurrence.activityId}${activityOccurrence.id}`}
                    >
                      <ActivityCard activityOccurrence={activityOccurrence} />
                    </li>
                  );
                }
              })}
            </ol>
          </li>
        );
      })}
    </ol>
  );
};

import { Heading } from "@components/Heading/Heading";
import { Text } from "@components/Text/Text";
import styles from "./patientNotes.module.scss";
import { GoBackButton } from "@/routes/healthCare/goBackButton";
import { useNavigate } from "react-router-dom";
import { useAcceptedPatientNote } from "@/api/patientNote";

export const PatientNotes = () => {
  const navigate = useNavigate();
  const {
    data: acceptedPatientNote,
    isPending,
    isError,
  } = useAcceptedPatientNote();

  // Accepted patient note is always available at this point so no need to render loading or error state
  if (isPending || isError || !acceptedPatientNote) {
    return null;
  }

  const splitConvertedTextInParagraphs = acceptedPatientNote.convertedText
    .split("\n")
    .filter((paragraph) => paragraph.trim() !== "");

  return (
    <>
      <GoBackButton onClick={() => navigate("..")} size="large" />
      <div className={styles.infoText}>
        <Heading level="h1" className={styles.heading}>
          Detta har hänt
        </Heading>
        <Text element="p" size="small" color="faded">
          Texten baseras på läkarens anteckning och har gjorts mer läsbar med
          AI.
        </Text>
        {splitConvertedTextInParagraphs.map((paragraph, index) => (
          <Text element="p" key={index} size="small">
            {paragraph}
          </Text>
        ))}
        <div className={styles.sentInfo}>
          <Text element="span" size="small" color="faded">
            Godkänd av {acceptedPatientNote.acceptedBy} hos Medoma
          </Text>
          <Text element="span" size="small" color="faded">
            {acceptedPatientNote.conversionTimeStamp?.substring(0, 10)}
          </Text>
        </div>
      </div>
    </>
  );
};

export const generateQueryString = (
  queryParameters: Record<string, unknown>,
) => {
  const url = new URL("http://someurl");
  const queryParametersArray = Object.entries(queryParameters);
  if (queryParametersArray.length === 0) {
    return "";
  }
  queryParametersArray.forEach(([key, value]) => {
    if (value) {
      if (Array.isArray(value)) {
        value.forEach((listItem) =>
          url.searchParams.append(key, String(listItem)),
        );
      } else {
        url.searchParams.append(key, String(value));
      }
    }
  });
  return url.search;
};

import { STRINGIFIED_SCOPES } from "@/routes/login";
import { UserManager } from "oidc-client-ts";

let logisticsApiUrl =
  import.meta.env.DEV && import.meta.env.VITE_LOCAL_LOGISTICS_BACKEND
    ? "https://localhost:7240/ref/care"
    : "https://logistics-dev.medoma.com/ref/care";

let logisticsSSEApiUrl =
  import.meta.env.DEV && import.meta.env.VITE_LOCAL_LOGISTICS_BACKEND
    ? "https://localhost:7240/care"
    : "https://logistics-dev.medoma.com/care";

let notificationApiUrl =
  import.meta.env.DEV && import.meta.env.VITE_LOCAL_LOGISTICS_BACKEND
    ? "https://localhost:7240/ref/notification/care"
    : "https://logistics-dev.medoma.com/ref/notification/care";

let devicesUrl =
  import.meta.env.DEV && import.meta.env.VITE_LOCAL_LOGISTICS_BACKEND
    ? "https://localhost:5001"
    : "https://devices-dev.medoma.com";

let patientApiUrl =
  import.meta.env.DEV && import.meta.env.VITE_LOCAL_PATIENTS_BACKEND
    ? "https://localhost:7023/ref/care"
    : "https://pas-dev.medoma.com/ref/care";

let patientHtmxApiUrl =
  import.meta.env.DEV && import.meta.env.VITE_LOCAL_PATIENTS_BACKEND
    ? "https://localhost:7023/care"
    : "https://pas-dev.medoma.com/care";

let authApiUrl =
  import.meta.env.DEV && import.meta.env.VITE_LOCAL_AUTH_BACKEND
    ? "https://localhost:5001"
    : "https://auth-dev.medoma.com";

let environment = import.meta.env.DEV ? "local" : "dev";

const hostname = window?.location?.hostname;

// Inspired by https://daveceddia.com/multiple-environments-with-react/
if (hostname === "care.medoma.com") {
  authApiUrl = "https://auth.medoma.com";
  logisticsApiUrl = "https://logistics.medoma.com/ref/care";
  logisticsSSEApiUrl = "https://logistics.medoma.com/care";
  notificationApiUrl = "https://logistics.medoma.com/ref/notification/care";
  devicesUrl = "https://devices.medoma.com";
  patientApiUrl = "https://pas.medoma.com/ref/care";
  patientHtmxApiUrl = "https://pas.medoma.com/care";
  environment = "prod";
} else if (hostname === "care.test.medoma.com") {
  authApiUrl = "https://auth-test.medoma.com";
  logisticsApiUrl = "https://logistics-test.medoma.com/ref/care";
  logisticsSSEApiUrl = "https://logistics-test.medoma.com/care";
  notificationApiUrl =
    "https://logistics-test.medoma.com/ref/notification/care";
  devicesUrl = "https://devices-test.medoma.com";
  patientApiUrl = "https://pas-test.medoma.com/ref/care";
  patientHtmxApiUrl = "https://pas-test.medoma.com/care";
  environment = "test";
} else if (hostname === "care.demo.medoma.com") {
  authApiUrl = "https://auth-demo.medoma.com";
  logisticsApiUrl = "https://logistics-demo.medoma.com/ref/care";
  logisticsSSEApiUrl = "https://logistics-demo.medoma.com/care";
  notificationApiUrl =
    "https://logistics-demo.medoma.com/ref/notification/care";
  devicesUrl = "https://devices-demo.medoma.com";
  patientApiUrl = "https://pas-demo.medoma.com/ref/care";
  patientHtmxApiUrl = "https://pas-demo.medoma.com/care";
  environment = "demo";
}

const oidcConfig = {
  authority: authApiUrl,
  client_id: "medoma-care-reference",
  redirect_uri: `${window.location.origin}/login`,
  // https://authts.github.io/oidc-client-ts/interfaces/UserManagerSettings.html#scope
  scope: STRINGIFIED_SCOPES, // default value: "openid"
};

export const userManager = new UserManager(oidcConfig);

export const {
  LOGISTICS_API_URL,
  LOGISTICS_SSE_API_URL,
  NOTIFICATION_API_URL,
  DEVICES_URL,
  AUTH_API_URL,
  PATIENT_API_URL,
  PATIENT_HTMX_API_URL,
  ENVIRONMENT,
} = {
  LOGISTICS_API_URL: logisticsApiUrl,
  LOGISTICS_SSE_API_URL: logisticsSSEApiUrl,
  NOTIFICATION_API_URL: notificationApiUrl,
  DEVICES_URL: devicesUrl,
  AUTH_API_URL: authApiUrl,
  PATIENT_API_URL: patientApiUrl,
  PATIENT_HTMX_API_URL: patientHtmxApiUrl,
  ENVIRONMENT: environment,
};

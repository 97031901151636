import { Heading } from "@components/Heading/Heading";
import styles from "./healthCare.module.scss";
import { useAcceptedPatientNote } from "@/api/patientNote";
import { Guide } from "./guide";
import { Text } from "@components/Text/Text";
import * as Sentry from "@sentry/react";
import { Loading } from "@components/Loading/Loading";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { NavCard } from "@/routes/healthCare/navCard";

export const HealthCare = () => {
  const {
    data: acceptedPatientNote,
    isPending,
    isError,
    error,
  } = useAcceptedPatientNote();
  if (isPending) {
    return <Loading message="Hämtar information om din vård" />;
  }

  if (isError) {
    Sentry.captureException(error);
    return (
      <ErrorMessage message="Något gick fel när vi hämtade information om din vård" />
    );
  }

  if (!acceptedPatientNote) {
    return <Guide goBackEnabled={false} />;
  }

  return (
    <>
      <Heading level="h1" className={styles.heading}>
        Din vård
      </Heading>
      <Text element="p">
        Här har vi samlat information om din vård. Att du förstår ditt
        medicinska tillstånd kan stärka din förmåga att aktivt bidra till din
        återhämtning.
      </Text>
      <div className={styles.navigationCards}>
        <NavCard
          link={{
            to: "/healthCare/patientNotes",
            title: "Detta har hänt",
            description: "2 minuters läsning",
          }}
        />
        <NavCard
          link={{
            to: "/healthCare/patientGuide",
            title: "Så vårdas du i hemmet",
            description: "1 minuts läsning",
          }}
        />
      </div>
    </>
  );
};
